import React from 'react'
import AdminSidebar from '../Components/AdminSidebar'

const AdminDashboard = () => {
  
  return (
    <>
    <AdminSidebar />
    </>
  )
}

export default AdminDashboard