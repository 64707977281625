export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_SAVE_SHIPPING_ADDRESS = 'CART_SAVE_SHIPPING_ADDRESS';
export const CART_SELECT_VARIANT = 'CART_SELECT_VARIANT';

export const CART_SAVE_PAYMENT_METHOD = 'CART_SAVE_PAYMENT_METHOD';

export const CART_CREATE_REQUEST = 'CART_CREATE_REQUEST';
export const CART_CREATE_SUCCESS = 'CART_CREATE_SUCCESS';
export const CART_CREATE_FAIL = 'CART_CREATE_FAIL';

export const GET_CART_ITEM_REQUEST = 'GET_CART_ITEM_REQUEST';
export const GET_CART_ITEM_SUCCESS = 'GET_CART_ITEM_SUCCESS';
export const GET_CART_ITEM_FAIL = 'GET_CART_ITEM_FAIL';

export const CART_REMOVE_ITEM_REQUEST = 'CART_REMOVE_ITEM_REQUEST';
export const CART_REMOVE_ITEM_SUCCESS = 'CART_REMOVE_ITEM_SUCCESS';
export const CART_REMOVE_ITEM_FAIL = 'CART_REMOVE_ITEM_FAIL';